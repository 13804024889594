import Card from "@alliancesafetycouncil/asc-card";
import React from "react";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { Field, Form, reduxForm } from "redux-form";
import TextInput from "../../../components/FormContainer/TextInput";
import TextEditor from "../../../components/TextEditor/TextEditor";

let EditRequirementForm = ({ requirementDetails, setRef, handleSubmit, submitting, history }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Card type="main">
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                name="name"
                id="name"
                placeholder=""
                label="Requirement Name"
                component={TextInput}
                className="form-control"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <TextEditor setRef={setRef} initialValue={requirementDetails.notes} />
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <Button color="primary" onClick={handleSubmit} disabled={submitting}>
                Save
              </Button>{" "}
              <Button color="secondary" onClick={() => history.goBack()}>
                Cancel
              </Button>{" "}
            </FormGroup>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

EditRequirementForm = reduxForm({
  form: "editRequirement",
})(EditRequirementForm);

export default EditRequirementForm;
